import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AdaptiveForm,
  IEnvTenantSiteFormParams,
  IFormElement,
  transformLegacyToAdaptiveForm,
  useLegacyForm,
} from '@rapid/adaptive-forms';
import { useHistory, useParams } from 'react-router-dom';
import qs from 'qs';
import {
  ILocalAttachment,
  useCurrentSite,
  useCurrentSiteEndpoint,
  useImmer,
  useList,
} from '@rapid/sdk';
import inflection from 'inflection';
import { Attachments } from './attachments';
import { AnyListItem } from '@rapid/data-model';
import { IEnvTenantSiteVersionFormParams } from './form-version-manager';
import { returnSectionsToSave } from './form';

export function LegacyForm() {
  const params = useParams<IEnvTenantSiteVersionFormParams>();
  const history = useHistory();
  const [form] = useLegacyForm(params.slug);
  const siteEndpoint = useCurrentSiteEndpoint();

  const adaptiveForm = transformLegacyToAdaptiveForm(form);

  const [list] = useList(form.list);

  const [item, updateItem] = useImmer<AnyListItem>({
    Attachments: [],
    ScopedLinkedItemsToAdd: [],
    LinkedItemsToRemove: [],
    __metadata: {
      type: list.ListName,
    },
  });

  useEffect(
    function parseFormParamsOnMount() {
      const paramsMatch = history.location.search.match(
        /\?params=(?<params>.*)/,
      )?.groups?.params;

      const parsedParams = atob(paramsMatch ?? '');

      const query = qs.parse(parsedParams);

      let scopedLinks: Record<string, any>[] = [];

      for (const [fieldRef, value] of Object.entries(query ?? {})) {
        const field = list.Fields.find(f => f.ColumnName === fieldRef);

        if (!!field && field.FieldType === 'MultiLookup') {
          const val: string[] = JSON.parse(value as string);
          scopedLinks = scopedLinks.concat(
            val.map(v => ({
              column_name: field.ColumnName,
              entity_type_id: list.TypeId,
              [`${inflection.underscore(list.ListNameSingular ?? '')}_id`]:
                v.match(/.*\/(?<id>.*)/)?.groups?.id,
            })),
          );
        }
      }

      updateItem(i => {
        for (const [key, value] of Object.entries(query ?? {})) {
          i[key] = value;
        }
        i.ScopedLinkedItemsToAdd = scopedLinks;
      });
    },
    [history.location.search, list],
  );

  const [site] = useCurrentSite();

  const submit = async () => {
    const saveSections = returnSectionsToSave(adaptiveForm, item, site);

    for (const section of saveSections) {
      await siteEndpoint.Lists[section.listName].Items.post({}, { ...item, ScopedLinkedItemsToAdd: section.ScopedLinkedItemsToAdd }).then(() => {
        updateItem(() => ({
          Attachments: [],
          ScopedLinkedItemsToAdd: [],
          LinkedItemsToRemove: [],
          __metadata: {
            type: list.ListName,
          },
        }));
      })
    }

    history.push(`/_${params.env}/${params.tenant}/${params.site}/${params.version}/${params.slug}/submitted`);
  };

  const onChange = (formElement: IFormElement, value: any) => {
    //     if (formElement.__metadata?.field?.FieldType === 'MultiLookup') {
    //       const ScopedLinkedItemsToAdd: any[] = [];
    //
    //       for (const link of value?.ScopedLinkedItemsToAdd) {
    //         ScopedLinkedItemsToAdd.push({
    //           column_name: formElement.__metadata?.field?.ColumnName,
    //           entity_type_id: list.TypeId,
    //           [`${inflection.underscore(
    //             inflection.singularize(link.__metadata.type),
    //           )}_id`]: link.id,
    //         });
    //       }
    //
    //       updateItem(i => {
    //         i.ScopedLinkedItemsToAdd = i.ScopedLinkedItemsToAdd.concat(
    //           ScopedLinkedItemsToAdd,
    //         );
    //         i.LinkedItemsToRemove = i.LinkedItemsToRemove.concat(
    //           value?.LinkedItemsToRemove,
    //         );
    //       });
    //     }

    if (formElement.$type === 'Input.Lookup' || formElement.$type === 'Input.User') {
      updateItem(i => {
        i[formElement.id] = value.value;
        i[`${formElement.id}_label`] = value.label;
      });
      return;
    }

    updateItem(i => {
      i[formElement.id] = value;
    });
  };

  const onAttachmentsChanged = (
    item: AnyListItem,
    attachments: ILocalAttachment[],
  ) => {
    updateItem(i => {
      i.Attachments = attachments;
    });
  };

  return (
    <div className="Form">
      <div className="Title">{form.title}</div>
      <div className="Description">{form.description}</div>
      <div className="FormContainer">
        <AdaptiveForm onChange={onChange} data={item} form={adaptiveForm} />
      </div>
      {form.showAttachments && (
        <Attachments
          item={item as any}
          onAttachmentsChanged={onAttachmentsChanged}
        />
      )}
      <button
        className="rp-button rp-button-primary Button"
        onClick={submit}
      >
        Submit
      </button>
    </div>
  );
}
