import React, { useRef, useState } from 'react';
import { AttachmentsContext, Dropzone, ILocalAttachment } from '@rapid/sdk';
import { ManagedAttachments } from './managed-attachments';
import { AnyListItem } from '@rapid/data-model';

interface IAttachmentsProps {
  onAttachmentsChanged(
    item: AnyListItem,
    attachments: ILocalAttachment[],
  ): void;
  item: AnyListItem;
}

export function Attachments({ onAttachmentsChanged, item }: IAttachmentsProps) {
  return (
    <AttachmentsContext onAttachmentsChanged={onAttachmentsChanged} item={item}>
      <ManagedAttachments />
    </AttachmentsContext>
  );
}
