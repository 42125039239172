import React, { useEffect } from 'react';
import {
  Dropzone,
  nameTag,
  useAttachmentContext,
  useAttachments,
} from '@rapid/sdk';
import { Attachment } from './attachment';

export function ManagedAttachments() {
  const { disabled } = useAttachmentContext();
  const [attachments, uploadAttachments, removeAttachment] = useAttachments();

  const onFilesReceived = (files: FileList) => {
    uploadAttachments(files);
  };

  const placeholder = disabled
    ? 'Attachments are disabled on this list'
    : 'Drag and drop or click to upload files';

  return (
    <Dropzone
      dropzoneClassName="Attachments"
      multiple
      onFilesReceived={onFilesReceived}
      disabled={disabled}
    >
      {!attachments.length && (
        <span className="Placeholder">{placeholder}</span>
      )}
      {attachments?.map(a => (
        <Attachment
          key={a[nameTag]}
          attachment={a}
          onRemoveAttachment={removeAttachment}
        />
      ))}
    </Dropzone>
  );
}
