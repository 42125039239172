import { useEffect, useState } from 'react';
import { useCurrentSiteEndpoint, useImmer } from '@rapid/sdk';
/*
  TODO
  This could be much nicer.
  You've already got a mangedviewocntext sitting above it
*/
export function useForm(slug) {
    const [form, updateForm] = useImmer({});
    const [loading, setLoading] = useState(true);
    const siteEndpoint = useCurrentSiteEndpoint();
    useEffect(function getFormOnMount() {
        setLoading(true);
        (async function getFormOnMountAsync() {
            const res = await siteEndpoint.Lists['Adaptive Forms'].All.Items.getJson({
                $filter: `slug eq '${slug}'`,
                $select: `id, title, slug, show_attachments, form_schema, description`,
            });
            updateForm(() => res.value[0]);
            setLoading(false);
        })();
    }, [slug]);
    async function saveForm() {
        let res;
        if (!!(form === null || form === void 0 ? void 0 : form.id)) {
            res = await siteEndpoint.Lists['Adaptive Forms'].Items[form.id].putJson({}, form);
        }
        else {
            res = await siteEndpoint.Lists['Adaptive Forms'].Items.postJson({}, form);
        }
        return res;
    }
    return [form, updateForm, saveForm, loading];
}
