import React, { useEffect, useRef, useState } from 'react';
import { SanitizedDiv, useComposedClassName } from '@rapid/sdk';
import {
  Editor as KendoEditor,
  EditorChangeEvent,
  ProseMirror
} from '@progress/kendo-react-editor';
import { placeholderPlug } from './placeholder';
import {
  descriptionTag,
  fieldAttributesSchemaTag,
  FormElementInputProps,
  iconTag,
  IFormElement,
  titleTag,
} from '@rapid/adaptive-forms';

export interface IMultiLineTextFieldAttributes {
  height: number;
}

export function MultiLineTextField({
  id,
  value,
  label,
  onChange,
  disabled,
  placeholder,
  height,
}: FormElementInputProps<string, IMultiLineTextFieldAttributes>) {
  const onMultilineChange = (e: EditorChangeEvent) => {
    onChange?.(e.html);
  };

  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const toggleFocus = (focus?: boolean) => () => {
    setHasFocus(!!focus);
  };

  const onKendoEditorMount = (event: any) => {
    const state = event.viewProps.state;
    const plugins = [...state.plugins, placeholderPlug(placeholder ?? '', id)];
    const doc = event.dom.ownerDocument;
    doc.querySelector('style')?.appendChild(doc.createTextNode(''));

    return new ProseMirror.EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: ProseMirror.EditorState.create({ doc: state.doc, plugins }),
      },
    );
  };

  const multiLineClass = useComposedClassName(
    function*() {
      yield `MultiLineTextField Label flex column`;

      if (hasFocus) yield 'focus';

      if (disabled) yield 'disabled';

      if (height) yield height.toString();
    },
    [hasFocus, disabled, height],
  );

  const labelRef = useRef<HTMLLabelElement>(null);

  useEffect(
    function setHeightCssVariableOnMount() {
      if (!!height) {
        console.log('height change');
        labelRef.current?.style.setProperty(
          '--multiline-height',
          `${height}rem`,
        );
      }
    },
    [height],
  );

  return (
    <label ref={labelRef} className={multiLineClass} id={id}>
      <span className="Name">{label}</span>
      <div
        className={`TextField Multiline ant-input ant-input-sm ${hasFocus ? 'focus' : ''
          }`}
      >
        {disabled ? (
          <SanitizedDiv html={value ?? placeholder ?? ''} />
        ) : (
          <KendoEditor
            defaultEditMode="div"
            value={value ?? ''}
            onChange={onMultilineChange}
            onMount={onKendoEditorMount}
            onFocus={toggleFocus(true)}
            onBlur={toggleFocus(false)}
          />
        )}
      </div>
    </label>
  );
}

MultiLineTextField[titleTag] = 'Multiple lines of text';

MultiLineTextField[descriptionTag] =
  'WYSIWYG Rich text editor allowing multiple lines of formatted text';

MultiLineTextField[iconTag] = 'fa-align-left';

MultiLineTextField[fieldAttributesSchemaTag] = [
  {
    $type: 'Input.Text',
    id: 'label',
    label: 'Label',
  },
  {
    $type: 'Input.Number',
    id: '$.attributes.height',
    label: 'Height',
    defaultValue: 3,
  },
  {
    $type: 'Input.MultiLineText',
    id: 'defaultValue',
    label: 'Default Value',
    attributes: {
      height: 3,
    },
  },
  {
    $type: 'Input.Text',
    id: 'placeholder',
    label: 'Placeholder',
  },
  {
    $type: 'Input.Boolean',
    id: 'required',
    label: 'Required',
  },
] as IFormElement[];
