import React, { useRef, useState } from 'react';
import { useComposedClassName } from '../use-composed-class-names';
/**
 * Dropzone
 *
 * Wraps children in a dropzone, with drag events available
 * and a clickable input wired up to click to upload files.
 */
export function Dropzone({ children, disabled, onDrop, onDragEnter, onDragLeave, onDragOver, onInputChange, onFilesReceived, multiple, inputClassName, dropzoneClassName, }) {
    const [dragOver, setDragOver] = useState(false);
    const onDropInner = (e) => {
        e.preventDefault();
        onFilesReceived(e.dataTransfer.files);
        setDragOver(false);
        if (!!onDrop) {
            e.persist();
            onDrop(e);
        }
    };
    const onChangeInner = (e) => {
        e.preventDefault();
        onFilesReceived(e.target.files);
        setDragOver(false);
        if (!!onInputChange) {
            e.persist();
            onInputChange(e);
        }
    };
    const onDragInner = (type) => (e) => {
        e.preventDefault();
        if (type === 'over') {
            if (!!onDragOver) {
                e.persist();
                onDragOver(e);
            }
            return;
        }
        if (type === 'enter') {
            setDragOver(true);
            if (!!onDragEnter) {
                e.persist();
                onDragEnter(e);
            }
            return;
        }
        if (type === 'leave') {
            setDragOver(false);
            if (!!onDragLeave) {
                e.persist();
                onDragLeave(e);
            }
            return;
        }
    };
    const inputRef = useRef(null);
    const onClick = (e) => {
        e.stopPropagation();
        inputRef.current.click();
    };
    const dropzoneClass = useComposedClassName(function* () {
        yield 'Dropzone';
        if (dragOver)
            yield 'over';
        if (dropzoneClassName)
            yield dropzoneClassName;
        if (disabled)
            yield 'disabled';
    }, [dragOver, dropzoneClassName, disabled]);
    const inputClass = useComposedClassName(function* () {
        yield 'FileInput';
        if (inputClassName)
            yield inputClassName;
    }, [inputClassName]);
    return (React.createElement("div", { onDragOver: onDragInner('over'), onDragEnter: onDragInner('enter'), onDragLeave: onDragInner('leave'), onDrop: onDropInner, className: dropzoneClass, onClick: onClick },
        children,
        React.createElement("input", { className: inputClass, multiple: multiple, type: "file", ref: inputRef, onChange: onChangeInner, disabled: disabled })));
}
