import { AnyListItem, IForm } from '@rapid/data-model';
import { IEnvTenantSiteParams, useComposedClassName } from '@rapid/sdk';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

interface IFormsListItemProps {
  form?: AnyListItem;
  legacyForm?: IForm;
}

export function FormsListItem({ form, legacyForm }: IFormsListItemProps) {
  const params = useParams<IEnvTenantSiteParams>();
  const history = useHistory();

  const onFormsListItemClick = () => {
    const version = !!legacyForm ? 'v1' : 'v2';

    history.push(
      `/_${params.env}/${params.tenant}/${params.site}/${version}/${
        form?.slug ?? legacyForm?.slug
      }`,
    );
  };

  const iconClass = useComposedClassName(
    function* () {
      yield 'Icon fas';

      if (!!form) yield 'fa-file-invoice';

      if (!!legacyForm) yield 'fa-file';
    },
    [form, legacyForm],
  );

  return (
    <div className="FormsListItem" onClick={onFormsListItemClick}>
      <i className={iconClass} />
      <div className="Title">{form?.title ?? legacyForm?.title}</div>
    </div>
  );
}
