import React, { useState } from 'react';
import { useComposedClassName, useThrottledFn } from '@rapid/sdk';
import { Input, Popconfirm, Tooltip } from 'antd';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../symbols';
export function EmailField({ id, columnName, value, label, onChange, disabled, }) {
    const [isValid, setIsValid] = useState(true);
    const validateInput = (value) => {
        if (!value) {
            setIsValid(true);
            return;
        }
        setIsValid(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value));
    };
    const throttledValidateInput = useThrottledFn(validateInput, 250, []);
    const onEmailChange = (e) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(e.target.value);
    };
    const onBlur = (e) => {
        throttledValidateInput(e.target.value);
    };
    const handleSendEmailClicked = (send) => () => {
        if (!send) {
            return;
        }
        window.open(`mailto:${value}`, '_blank');
    };
    const emailFieldClass = useComposedClassName(function* () {
        yield 'Email Label flex column';
        if (!!columnName)
            yield columnName;
        if (disabled)
            yield 'disabled';
        if (isValid) {
            !(value === null || value === void 0 ? void 0 : value.length) ? yield 'empty' : yield 'valid';
        }
        else {
            yield 'invalid';
        }
    }, [columnName, disabled, isValid, value]);
    return (React.createElement("label", { className: emailFieldClass, id: id },
        React.createElement("span", { className: "Name" }, label),
        React.createElement(Input, { size: "small", disabled: disabled, onChange: onEmailChange, onBlur: onBlur, value: value !== null && value !== void 0 ? value : '', allowClear: true, suffix: React.createElement(Popconfirm, { overlayClassName: "EmailConfirmPopover", disabled: !isValid || !(value === null || value === void 0 ? void 0 : value.length), title: `Send an email to ${value}`, okText: "Yes", cancelText: "No", onConfirm: handleSendEmailClicked(true), onCancel: handleSendEmailClicked(false), icon: React.createElement("i", { className: "fal fa-question-circle" }) },
                React.createElement(Tooltip, { visible: !isValid, title: "Doesn't match email pattern i.e. name@address.com" },
                    React.createElement("i", { className: "EmailIcon fas fa-envelope" }))) })));
}
EmailField[titleTag] = 'Email';
EmailField[descriptionTag] = 'Field with built in email validation';
EmailField[iconTag] = 'fa-envelope';
EmailField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Email',
        id: 'defaultValue',
        label: 'Default Value',
    },
    {
        $type: 'Input.Text',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
