import { DatePicker, Space } from 'antd';
import React, { useMemo } from 'react';
import moment from 'moment';
import { useComposedClassName } from '@rapid/sdk';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../../symbols';
export function DateTimeField({ label, value, onChange, disabled, id, showTime, }) {
    const onChangeInner = (value, dateString) => {
        if (value === null || value === void 0 ? void 0 : value.isValid) {
            onChange === null || onChange === void 0 ? void 0 : onChange(value.toDate());
        }
        else {
            onChange === null || onChange === void 0 ? void 0 : onChange(null);
        }
    };
    const dateTimeClass = useComposedClassName(function* () {
        yield 'Label DateTimeField flex column';
        if (disabled)
            yield 'disabled';
    });
    const madMoment = useMemo(() => value && moment(value), [value]);
    return (React.createElement("label", { className: dateTimeClass, id: id },
        React.createElement("span", { className: "Name" }, label),
        React.createElement(DatePicker, { getPopupContainer: t => t, showTime: showTime && { format: 'HH:mm' }, format: showTime ? 'MMMM Do YYYY - HH:mm' : 'MMMM Do YYYY', disabled: disabled, onChange: onChangeInner, value: madMoment, suffixIcon: React.createElement(Space, null,
                React.createElement("i", { className: "fal fa-calendar" }),
                showTime && React.createElement("i", { className: "fal fa-clock" })) })));
}
DateTimeField[titleTag] = 'Date and time';
DateTimeField[descriptionTag] =
    'Select a date and time from a calendar dropdown';
DateTimeField[iconTag] = 'fa-calendar-day';
DateTimeField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.DateTime',
        id: 'defaultValue',
        label: 'Default Value',
    },
    {
        $type: 'Input.DateTime',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.Boolean',
        id: '$.attributes.showTime',
        label: 'Show Time Picker',
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
