import { useRapidApplication } from "@rapid/sdk"

export function AppTitle() {
  const [app] = useRapidApplication();

  return (
    <div className="AppTitle">
      <i className="fak fa-rapid-brand-mark" /> {app.displayName}
    </div>
  )
}

