import React, { useRef, useState } from 'react';
import { useComposedClassName } from '@rapid/sdk';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../symbols';
export function BooleanField({ id, value, label, disabled, onChange, }) {
    const checkboxRef = useRef(null);
    const onChangeInner = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(!value);
    };
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            onChange(!value);
        }
        if (e.key === 'Space') {
            e.stopPropagation();
            e.preventDefault();
            onChange(!value);
        }
    };
    const [isFocused, setIsFocused] = useState(false);
    const onFocus = () => {
        setIsFocused(true);
    };
    const onBlur = () => {
        setIsFocused(false);
    };
    const onCheckboxChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(!value);
    };
    const labelClass = useComposedClassName(function* () {
        yield 'Label flex column';
        if (isFocused)
            yield 'focus';
        if (disabled)
            yield 'disabled';
    }, [isFocused, disabled]);
    const booleanClass = useComposedClassName(function* () {
        yield 'BooleanField';
        if (!!value)
            yield 'on';
        if (!value)
            yield 'off';
    }, [value]);
    return (React.createElement("label", { className: labelClass, id: id },
        React.createElement("span", { className: "Name" }, label),
        React.createElement("div", { className: booleanClass, onClick: onChangeInner },
            React.createElement("div", { className: "Toggle" },
                React.createElement("div", { className: "Switch" },
                    !value && React.createElement("i", { className: "far fa-times fa-fw Off" }),
                    value && React.createElement("i", { className: "far fa-check fa-fw On" }))),
            React.createElement("div", { className: "Label" },
                !value && React.createElement("span", { className: "Off" }, "No"),
                value && React.createElement("span", { className: "On" }, "Yes")),
            React.createElement("input", { disabled: disabled, ref: checkboxRef, type: "checkbox", checked: !!value, onKeyDown: onKeyDown, onBlur: onBlur, onFocus: onFocus, onChange: onCheckboxChange }))));
}
BooleanField[titleTag] = 'Boolean';
BooleanField[descriptionTag] = 'Boolean toggle';
BooleanField[iconTag] = 'fa-toggle-on';
BooleanField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Boolean',
        id: 'defaultValue',
        label: 'Default Value',
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
