import { useCurrentSite, useList, } from '@rapid/sdk';
export function useSiteForms() {
    const [site, updateSite] = useCurrentSite();
    return [
        site.Forms,
        function updateFormsList(draft) {
            updateSite(d => {
                const f = d.Forms;
                if (!!f) {
                    draft(f);
                }
            });
        },
    ];
}
export function useLegacyForm(slug) {
    const [forms, updateForms] = useSiteForms();
    return [
        forms.items.find(f => f.slug === slug),
        function updateForm(draft) {
            updateForms(d => {
                const f = d.items.find(f => f.slug === slug);
                if (!!f) {
                    draft(f);
                }
            });
        },
    ];
}
export function returnFieldValue(type) {
    switch (type) {
        case 'Input.Boolean':
            return false;
        default:
            return '';
    }
}
export function processConditions(conditions) {
    const adaptiveConditions = [];
    for (const cond of conditions !== null && conditions !== void 0 ? conditions : []) {
        let validation = cond.validation;
        adaptiveConditions.push({
            validation: validation,
            elementRef: cond.elementRef,
            hideWhenValid: cond.hideWhenValid,
        });
    }
    return adaptiveConditions;
}
export function transformLegacyToAdaptiveForm(legacyForm) {
    var _a, _b, _c, _d;
    const adaptiveForm = {
        $type: 'Layout.Section',
        $children: [],
        id: `${legacyForm === null || legacyForm === void 0 ? void 0 : legacyForm.slug}_form`,
        label: legacyForm === null || legacyForm === void 0 ? void 0 : legacyForm.title,
        attributes: {
            columnName: `${legacyForm === null || legacyForm === void 0 ? void 0 : legacyForm.slug}_form`,
        },
    };
    const [list] = useList(legacyForm === null || legacyForm === void 0 ? void 0 : legacyForm.list);
    for (const field of (_a = legacyForm === null || legacyForm === void 0 ? void 0 : legacyForm.fields) !== null && _a !== void 0 ? _a : []) {
        const listField = list.Fields.find(f => f.ColumnName === field.columnName);
        const type = returnAdaptiveFieldType(listField.FieldType);
        const formField = {
            $type: type,
            id: field.columnName,
            label: listField.Title,
            conditions: processConditions(field.conditions),
            defaultValue: field.defaultValue,
            attributes: {
                columnName: field.columnName,
                choices: (_b = listField.Settings) === null || _b === void 0 ? void 0 : _b.Choices,
                lookupField: type === 'Input.User'
                    ? 'display_name'
                    : (_c = listField.Settings) === null || _c === void 0 ? void 0 : _c.LookupField,
                lookupList: type === 'Input.User' ? 'Principals' : (_d = listField.Settings) === null || _d === void 0 ? void 0 : _d.LookupList,
                list: list,
                field: listField,
            },
        };
        adaptiveForm['$children'].push(formField);
    }
    return adaptiveForm;
}
export function returnAdaptiveFieldType(fieldType) {
    switch (fieldType) {
        case 'Text':
            return 'Input.Text';
        case 'Email':
            return 'Input.Email';
        case 'Note':
            return 'Input.MultiLineText';
        case 'DateTime':
            return 'Input.DateTime';
        case 'Choice':
            return 'Input.Choice';
        case 'Lookup':
            return 'Input.Lookup';
        case 'Boolean':
            return 'Input.Boolean';
        case 'Number':
            return 'Input.Number';
        case 'Integer':
            return 'Input.WholeNumber';
        case 'Percentage':
            return 'Input.Percentage';
        case 'Currency':
            return 'Input.Currency';
        case 'MultiLookup':
            return 'Input.MultiLookup';
        case 'User':
            return 'Input.User';
        case 'Computed':
            return 'Input.Computed';
        case 'Subquery':
            return 'Input.Subquery';
        default:
            return fieldType;
    }
}
