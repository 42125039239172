import React, { useMemo } from 'react';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../symbols';
import { useComposedClassName } from '@rapid/sdk';
export function RadioField({ choices, label, direction, onChange, disabled, justifyLeft, }) {
    const uuid = useMemo(function getRadioFieldId() {
        const pallet = '0123456789ABCDEFGHIJKLMOPQRSTUVWZYZ';
        let id = '';
        for (let i = 0; i < 10; i++) {
            id += pallet[Math.floor(Math.random() * pallet.length - 1)];
        }
        return id;
    }, []);
    const directionClassName = useComposedClassName(function* () {
        yield 'RadioField__inputs';
        if (direction)
            yield direction;
        if (disabled)
            yield 'disabled';
        if (justifyLeft)
            yield 'justify-left';
    }, [direction, disabled, justifyLeft]);
    const onChoiceClicked = (value) => () => {
        onChange === null || onChange === void 0 ? void 0 : onChange(value.Text);
    };
    function renderChoices(choices) {
        return choices === null || choices === void 0 ? void 0 : choices.map(choice => {
            return (React.createElement("label", { key: choice.Text, className: "RadioField__label" },
                React.createElement("input", { disabled: disabled, onClick: onChoiceClicked(choice), name: uuid, id: uuid, type: "radio", value: choice.Value }),
                React.createElement("span", null, choice.Text)));
        });
    }
    return (React.createElement("div", { className: "RadioField Field" },
        React.createElement("span", { className: "Name" }, label),
        React.createElement("div", { className: directionClassName }, renderChoices(choices))));
}
RadioField[titleTag] = 'Radio';
RadioField[descriptionTag] = 'Select a single choice from a radio button list';
RadioField[iconTag] = 'fa-ballot-check';
RadioField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Boolean',
        label: 'Justify Left',
        id: '$.attributes.justifyLeft',
    },
    {
        $type: 'Input.Array',
        label: 'Choices',
        id: '$.attributes.choices',
        attributes: {
            shape: {
                Text: '',
            },
            path: '$.Text',
        },
    },
];
