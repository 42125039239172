import { ManagedMultiSiteApplication, ManagedViewContext } from '@rapid/sdk';
import ReactDOM from 'react-dom';
import { Redirect, Route } from 'react-router-dom';
import { FormVersionManager } from './form-version-manager';
import { FormsList } from './forms-list';
import { Submitted } from './submitted';
// import 'antd/dist/antd.css';
import '@rapid/adaptive-forms/css/adaptive-forms.css';
import { AppTitle } from './components/app-title';

ReactDOM.render(
  <ManagedMultiSiteApplication
    clientId="cd5db0ec-1419-4ae6-9434-21cfb83fc42d"
    namespace="rapid::ui-forms-react"
    displayName="Forms"
    className="Forms"
  >
    <ManagedViewContext dataSource="All" list="Adaptive Forms">
      <div className="Sidebar">
        <AppTitle />
      </div>
      <Route exact path={`/_:env/:tenant/:site/:version/:slug`}>
        <FormVersionManager />
      </Route>
      <Route path={`/_:env/:tenant/:site/:version/:slug/submitted`}>
        <Submitted />
      </Route>
      <Route exact path={`/_:env/:tenant/:site/:version?`}>
        <FormsList />
      </Route>
    </ManagedViewContext>
  </ManagedMultiSiteApplication>,
  document.getElementById('root'),
);
