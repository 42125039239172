import { useComposedClassName } from '@rapid/sdk';
import { Select } from 'antd';
import React, { useMemo } from 'react';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../../symbols';
export function ChoiceField({ label, id, value, disabled, choices, onChange, valueKey, labelKey, }) {
    const options = useMemo(function buildOptionsFromChoices() {
        const options = [];
        for (const choice of choices !== null && choices !== void 0 ? choices : []) {
            // TODO This needs to use the
            // Classnames and css variables
            // hook that tyler made
            options.push({
                label: (React.createElement("div", { style: {
                        display: 'flex',
                        alignItems: 'center',
                    } },
                    React.createElement("span", { style: {
                            marginRight: '5px',
                            backgroundColor: choice === null || choice === void 0 ? void 0 : choice.AccentColour,
                            height: '10px',
                            width: '10px',
                            borderRadius: '3px',
                        } }),
                    choice[labelKey !== null && labelKey !== void 0 ? labelKey : 'Text'])),
                value: choice[valueKey !== null && valueKey !== void 0 ? valueKey : 'Text'],
                title: choice[labelKey !== null && labelKey !== void 0 ? labelKey : 'Text'],
            });
        }
        return options;
    }, [choices]);
    const choiceClass = useComposedClassName(function* () {
        yield 'Choice Label flex column';
        if (disabled)
            yield 'disabled';
    }, [choices, disabled]);
    const onChangeInner = (value, option) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    };
    return (React.createElement("label", { className: choiceClass, id: id },
        React.createElement("span", { className: "Name" }, label),
        React.createElement(Select, { getPopupContainer: t => t.parentElement, disabled: disabled, showSearch: true, value: value, onChange: onChangeInner, options: options })));
}
ChoiceField[titleTag] = 'Choice';
ChoiceField[descriptionTag] = 'Select from a dropdown of choices';
ChoiceField[iconTag] = 'fa-clipboard-list-check';
ChoiceField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Text',
        id: 'defaultValue',
        label: 'Default Value',
    },
    {
        $type: 'Input.Text',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
