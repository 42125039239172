export function getFileIcon(mimeType) {
    if (mimeType.includes('pdf')) {
        return 'fa-file-pdf';
    }
    if (mimeType.includes('word') ||
        mimeType.includes('msword') ||
        mimeType.includes('officedocument.wordpocessingml.document')) {
        return 'fa-file-word';
    }
    if (mimeType.includes('book') ||
        mimeType.includes('epud') ||
        mimeType.includes('officedocument.wordpocessingml.document')) {
        return 'fa-book';
    }
    if (mimeType.includes('json') ||
        mimeType.includes('javascript') ||
        mimeType.includes('css') ||
        mimeType.includes('html') ||
        mimeType.includes('java') ||
        mimeType.includes('php') ||
        mimeType.includes('x-sh') ||
        mimeType.includes('xml') ||
        mimeType.includes('x-csh')) {
        return 'fa-file-code';
    }
    if (mimeType.includes('pdf')) {
        return 'fa-file-pdf';
    }
    if (mimeType.includes('csv')) {
        return 'fa-file-csv';
    }
    if (mimeType.includes('icon')) {
        return 'fa-icons-alt';
    }
    if (mimeType.includes('calendar')) {
        return 'fa-calendar-alt';
    }
    if (mimeType.includes('text')) {
        return 'fa-file-alt';
    }
    if (mimeType.includes('audio')) {
        return 'fa-file-audio';
    }
    if (mimeType.includes('image')) {
        return 'fa-file-image';
    }
    if (mimeType.includes('font')) {
        return 'fa-font-case';
    }
    if (mimeType.includes('application') ||
        mimeType.includes('zip') ||
        mimeType.includes('freearc') ||
        mimeType.includes('apple.installer') ||
        mimeType.includes('compressed')) {
        return 'fa-file-archive';
    }
    if (mimeType.includes('video')) {
        return 'fa-file-video';
    }
    return 'fa-file';
}
