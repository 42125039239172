import React from 'react';
import { useParams } from 'react-router';
import { IEnvTenantSiteFormParams } from '@rapid/adaptive-forms';
import { Form } from './form';
import { LegacyForm } from './legacy-form';

export interface IEnvTenantSiteVersionFormParams
  extends IEnvTenantSiteFormParams {
  version?: string;
}

export function FormVersionManager() {
  const params = useParams<IEnvTenantSiteVersionFormParams>();

  if (params.version === 'v1') {
    return <LegacyForm />;
  }

  return <Form />;
}
