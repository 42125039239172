import { Button, Space } from "antd";
import { useHistory, useParams } from "react-router"
import { IEnvTenantSiteVersionFormParams } from "./form-version-manager";

interface ISubmittedProps { }

export function Submitted({ }: ISubmittedProps) {
  const history = useHistory();
  const params = useParams<IEnvTenantSiteVersionFormParams>();

  const onStartAgainClick = () => {
    history.push(`/_${params.env}/${params.tenant}/${params.site}/${params.version}/${params.slug}`);
  }

  const onReturnClick = () => {
    history.push(`/_${params.env}/${params.tenant}/${params.site}`);
  }

  return (
    <div className="Submitted AdaptiveForm">
      <i className="Icon fal fa-comment-alt-smile" />
      <div className="Title">Thankyou for your submission.</div>
      <Space>
        <Button
          type="primary"
          size="large"
          onClick={onStartAgainClick}
        >
          Start form again
        </Button>
        <Button
          type="default"
          size="large"
          onClick={onReturnClick}
        >
          Return to form selection
        </Button>
      </Space>
    </div>
  )
}
