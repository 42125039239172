import React from 'react';
import {
  blobTag,
  blobUrlTag,
  ILocalAttachment,
  nameTag,
  percentTag,
  useComposedClassName,
} from '@rapid/sdk';
import { ProgressBar } from './progress-bar';

interface IAttachmentProps {
  attachment: ILocalAttachment;
  onRemoveAttachment(attachment: ILocalAttachment): void;
}

export function Attachment({
  attachment,
  onRemoveAttachment,
}: IAttachmentProps) {
  const onClick =
    (attachment: ILocalAttachment) =>
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onRemoveAttachment(attachment);
    };

  const iconClass = useComposedClassName(
    function* () {
      yield 'Icon fad';

      if (attachment?.[blobTag]?.type.includes('application')) {
        yield 'fa-file-archive';
        return;
      }

      if (attachment?.[blobTag]?.type.includes('audio')) {
        yield 'fa-file-audio';
        return;
      }

      if (attachment?.[blobTag]?.type.includes('image')) {
        yield 'fa-image';
        return;
      }

      if (attachment?.[blobTag]?.type.includes('text')) {
        yield 'fa-file-alt';
        return;
      }

      if (attachment?.[blobTag]?.type.includes('video')) {
        yield 'fa-file-video';
        return;
      }

      yield 'fa-file';
    },
    [attachment?.[blobTag]?.type],
  );

  return (
    <div className="Attachment">
      <div className="Image">
        {!!attachment[blobUrlTag] ? (
          <img src={attachment[blobUrlTag]} />
        ) : (
          <i className={iconClass} />
        )}
      </div>
      <div className="Name" title={attachment[nameTag]}>
        {attachment[nameTag]}
      </div>
      {attachment[percentTag] !== undefined ? (
        <ProgressBar percent={attachment[percentTag]} />
      ) : (
        <div className="Actions">
          {attachment[percentTag] === undefined && (
            <button className="Delete Button" onClick={onClick(attachment)}>
              <i className="Delete far fa-trash-alt" />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
