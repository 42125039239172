import React from 'react';
import { ChoiceField, DateTimeField, EmailField, LookupField, MultilineTextField, } from './index';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../symbols';
import { BooleanField } from './boolean-field';
import { MultiLookupField } from './multi-lookup-field/multi-lookup-field';
import { NumberField } from './number-field';
import { TextField } from './text-field';
export function ComputedField(props) {
    switch (props.DisplayAs) {
        case 'Boolean':
            return React.createElement(BooleanField, { ...props, disabled: true });
        case 'Note':
            return (React.createElement(MultilineTextField, { ...props, disabled: true }));
        case 'Number':
        case 'Currency':
        case 'Percentage':
        case 'Integer':
            return React.createElement(NumberField, { ...props, disabled: true });
        case 'Email':
            return React.createElement(EmailField, { ...props, disabled: true });
        case 'DateTime':
            return React.createElement(DateTimeField, { ...props, disabled: true });
        case 'MultiLookup':
            return (React.createElement(MultiLookupField, { ...props, disabled: true }));
        case 'Lookup':
        case 'User':
            return React.createElement(LookupField, { ...props, disabled: true });
        case 'Choice':
            return React.createElement(ChoiceField, { ...props, disabled: true });
        default:
            return React.createElement(TextField, { ...props, disabled: true });
    }
}
ComputedField[titleTag] = 'Computed';
ComputedField[descriptionTag] = 'RAPID Platform computed field';
ComputedField[iconTag] = 'fa-computer-classic';
ComputedField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
];
