import { useGraphAuthenticatedFetch } from './authentication';
import { proxyRestClient, } from './proxy-rest-client';
/**
 * Uses an API client for communicating with the Microsoft 365 Graph
 */
export function useGraphEndpoint(...scopes) {
    const fetch = useGraphAuthenticatedFetch(...scopes);
    const client = proxyRestClient('https://graph.microsoft.com/v1.0', fetch);
    return client;
}
