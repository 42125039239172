import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AdaptiveForm,
  IFormElement,
  MultilineTextField,
  useForm,
} from '@rapid/adaptive-forms';
import { IEnvTenantSiteVersionFormParams } from './form-version-manager';
import {
  SanitizedDiv,
  useCurrentSite,
  useCurrentSiteEndpoint,
  useImmer,
} from '@rapid/sdk';
import { AnyListItem, ISite } from '@rapid/data-model';
import inflection from 'inflection';
import { MultiLineTextField } from './components/multi-lookup-field';

/* 
    TODO
    In this guy we want to keep a store of filled forms
    in the localStorage so we can then refresh forms
    when people return or if they accidentally refresh.

    Needs to:
    - Add to local storage in componentWillUnmount
    - Check local storage on mount for filled forms
    - Pop notification asking if the user wants the form fileld from localStorage
    - Fill the form or remove the data from localStorage
*/

interface IFormSaveSections {
  listName: string;
  id?: string;
  body?: Record<string, any>;
  ScopedLinkedItemsToAdd?: IScopedLikedItemToAdd[];
}

export function returnSectionChildren(
  element: IFormElement,
  data: Record<string, any>,
  listName?: string,
) {
  let body: Record<string, any> = {};

  if ((element.attributes?.listName ?? element.attributes?.list?.ListName) !== listName) {
    return body;
  }

  if (!!data[element.id]) {
    body[element.attributes?.columnName] =
      data[element.id];
  }

  if (!!element.$children?.length) {
    for (const child of element.$children) {
      body = { ...body, ...returnSectionChildren(child, data, listName) };
    }
  }

  return body;
}

export function returnSectionsToSave(
  element: IFormElement,
  data: Record<string, any>,
  site?: ISite,
) {
  let sections: IFormSaveSections[] = [];

  if (!!element.attributes?.listName || !!element.attributes?.list?.ListName) {
    const children = returnSectionChildren(
      element,
      data,
      element.attributes?.listName ?? element.attributes?.list?.ListName,
    );

    const [ScopedLinkedItemsToAdd] = processLookups(element, data, site);

    if (!!Object.values(children).length) {
      sections.push({
        listName: element.attributes.listName ?? element.attributes?.list?.ListName,
        id: element.attributes?.id,
        body: {
          ...children,
        },
        ScopedLinkedItemsToAdd,
      });
    }
  }

  if (!!element.$children?.length) {
    for (const child of element.$children) {
      const childSection = returnSectionsToSave(child, data, site);

      sections.push(...childSection);
    }
  }

  const uniqSections = uniqSectionsByListName(sections);

  return uniqSections;
}

export function uniqSectionsByListName(
  sections: IFormSaveSections[],
): IFormSaveSections[] {
  let uniqSections: IFormSaveSections[] = [];

  for (const section of sections) {
    const index = uniqSections.findIndex(s => s.listName === section.listName);

    if (index !== -1) {
      const newSection = uniqSections[index];
      newSection.body = { ...newSection.body, ...section.body };
      newSection.ScopedLinkedItemsToAdd = [
        ...(newSection.ScopedLinkedItemsToAdd ?? []),
        ...(section.ScopedLinkedItemsToAdd ?? []),
      ];
      uniqSections.splice(index, 1, newSection);
      continue;
    }

    uniqSections.push(section);
  }
  return uniqSections;
}

export interface IScopedLikedItemToAdd extends Record<string, any> {
  column_name: string;
  entity_type_id?: number;
}

export interface IMultiLookupOption {
  label: React.ReactNode;
  value: string | number;
  item: AnyListItem;
  column_name: string;
}

export function processLookups(
  element: IFormElement,
  data: Record<string, any>,
  site?: ISite,
): [IScopedLikedItemToAdd[], string[]] {
  // TODO This needs to add to LinkedItemsToRemove as well

  let ScopedLinkedItemsToAdd: IScopedLikedItemToAdd[] = [];
  let LinkedItemsToRemove: string[] = [];

  const list = site?.Lists.find(
    l => l.ListName === (element.attributes?.listName ?? element.attributes?.list?.ListName),
  );

  const entityName = inflection
    .transform(element.attributes?.lookupList ?? '', [
      'titleize',
      'singularize',
    ])
    .replaceAll(' ', '_')
    .toLocaleLowerCase();

  if (element.$type === 'Input.MultiLookup') {
    for (const { item, column_name } of data[
      element.id
    ] ?? []) {

      const itemId = item.id;

      console.log(itemId, list);

      const slita = {
        column_name: column_name,
        entity_type_id: list?.TypeId,
        [`${entityName}_id`]: itemId,
      }

      console.log(slita);

      ScopedLinkedItemsToAdd.push(slita);
    }
  }

  if (!!element.$children?.length) {
    for (const child of element.$children) {
      let [childScoped, childLinked] = processLookups(child, data, site);

      ScopedLinkedItemsToAdd.concat(childScoped);
      LinkedItemsToRemove.concat(childLinked);
    }
  }

  return [ScopedLinkedItemsToAdd, LinkedItemsToRemove];
}

export function Form() {
  const params = useParams<IEnvTenantSiteVersionFormParams>();
  const history = useHistory();

  const siteEndpoint = useCurrentSiteEndpoint();

  const [item, updateItem] = useImmer<Record<string, any>>({});

  const [form] = useForm(params.slug);

  const [site] = useCurrentSite();

  const onChange = (element: IFormElement, value: any) => {

    updateItem(d => {
      if (element.$type === 'Input.Lookup' || element.$type === 'Input.User') {
        d[element.id] = value.value;
        d[`${element.id}_label`] =
          value.label;
        return;
      }
      d[element.id] = value;
    });
  };

  const submit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const saveSections = returnSectionsToSave(form?.form_schema, item, site);

    for (const section of saveSections) {
      if (!!section.id) {
        siteEndpoint.Lists[section.listName].All.Items[section.id].put(
          {},
          {
            ...section.body,
            ScopedLinkedItemsToAdd: section.ScopedLinkedItemsToAdd,
          },
        );
      }

      siteEndpoint.Lists[section.listName].Items.post(
        {},
        {
          ...section.body,
          ScopedLinkedItemsToAdd: section.ScopedLinkedItemsToAdd,
        },
      );
      continue;
    }

    history.push(`/_${params.env}/${params.tenant}/${params.site}/${params.version}/${params.slug}/submitted`);
  };

  return (
    <div className="Form">
      <div className="FormScroll">
        <div className="Title">{form?.title}</div>
        <SanitizedDiv html={form?.description ?? ''} />
        <div className="FormContainer">
          <AdaptiveForm
            data={item}
            onChange={onChange}
            form={form.form_schema ?? { $type: 'Layout.Section', id: 'root' }}
            components={
              {
                'Input.MultiLineText': MultiLineTextField as any
              }
            }
          />
        </div>
      </div>
      <div className="Button">
        <button className="rp-button rp-button-primary" onClick={submit}>
          Submit
        </button>
      </div>
    </div>
  );
}
