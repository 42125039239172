import React, { useContext, useEffect } from 'react';
import { useCurrentSiteEndpoint } from '../use-current-site-endpoint';
import { useDataSource } from '../use-data-source';
import { useImmer } from '../use-immer';
import { useList } from '../use-list';
const Context = React.createContext(undefined);
function* itemContextIterator() {
    for (const item of Object.values(this)) {
        yield item;
    }
}
/**
 * Declares a view context which fetches and manages access to list items inside
 * that view.
 *
 * All components inside this context have access to the <Hooks TBD> hooks.
 *
 * @component
 * @category RAPID Application
 */
export function ManagedViewContext({ list, dataSource, columns, filter, searchTerm, linkedTo, count, disableAutoFetch, children, }) {
    const [innerList] = useList(list);
    const [innerDs] = useDataSource(list, dataSource !== null && dataSource !== void 0 ? dataSource : 'All');
    const ep = useCurrentSiteEndpoint();
    const fetchItems = async () => {
        var _a, _b, _c;
        updateContext(draft => {
            draft.loading = true;
        });
        try {
            if (!count) {
                const data = await ep.Lists[innerList.ListName][(_a = innerDs === null || innerDs === void 0 ? void 0 : innerDs.Title) !== null && _a !== void 0 ? _a : dataSource].Items.getJson({
                    term: searchTerm,
                    $filter: filter,
                    $select: columns === null || columns === void 0 ? void 0 : columns.join(','),
                    linkedTo,
                });
                updateContext(draft => {
                    draft.items = data.value.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {
                        [Symbol.iterator]: itemContextIterator,
                    });
                });
            }
            else {
                const res = await ep.Lists[innerList.ListName][(_b = innerDs === null || innerDs === void 0 ? void 0 : innerDs.Title) !== null && _b !== void 0 ? _b : dataSource].Items.$count.get({
                    term: searchTerm,
                    $filter: (_c = context.filter) !== null && _c !== void 0 ? _c : filter,
                    linkedTo,
                });
                if (res.ok) {
                    const value = await res.text();
                    updateContext(draft => {
                        draft.count = +value;
                    });
                }
            }
        }
        finally {
            updateContext(draft => {
                draft.loading = false;
            });
        }
    };
    async function fetchItem(id) {
        var _a;
        const data = await ep.Lists[innerList.ListName][(_a = innerDs === null || innerDs === void 0 ? void 0 : innerDs.Title) !== null && _a !== void 0 ? _a : dataSource].Items[id].getJson();
        updateContext(draft => {
            draft.items[data.id] = data;
        });
    }
    async function deleteItems(ids) {
        var _a;
        updateContext(draft => {
            draft.loading = true;
        });
        try {
            const res = await ep.Lists[innerList.ListName][(_a = innerDs === null || innerDs === void 0 ? void 0 : innerDs.Title) !== null && _a !== void 0 ? _a : dataSource].Items.delete({}, [...ids]);
            if (res.ok) {
                updateContext(draft => {
                    for (const id of ids) {
                        delete draft.items[id];
                    }
                });
            }
        }
        finally {
            updateContext(draft => {
                draft.loading = false;
            });
        }
    }
    const [context, updateContext] = useImmer({
        columns,
        list: innerList,
        dataSource: innerDs,
        filter,
        loading: false,
        count: undefined,
        items: {
            [Symbol.iterator]: itemContextIterator,
        },
    });
    useEffect(function fetchItemsEffect() {
        if (!disableAutoFetch) {
            fetchItems();
        }
    }, [disableAutoFetch, list, dataSource, columns, filter, searchTerm, linkedTo]);
    return (React.createElement(Context.Provider, { value: [
            { ...context, fetchItems, fetchItem, deleteItems },
            updateContext,
        ] }, children));
}
/**
 * Provides access to the view anywhere underneath a {@link ManagedViewContext}.
 *
 * @hook
 * @category RAPID Application
 */
export function useViewContext() {
    const context = useContext(Context);
    return context;
}
export function useViewContextCount() {
    const [context] = useViewContext();
    return [context.count, context.loading];
}
