import { ProseMirror } from '@progress/kendo-react-editor';
export function placeholderPlug(placeholder, id) {
    return new ProseMirror.Plugin({
        key: new ProseMirror.PluginKey('placeholder'),
        props: {
            decorations: state => {
                const { doc } = state;
                const empty = doc.textContent === '' &&
                    doc.childCount <= 1 &&
                    doc.content.size <= 2;
                if (!empty) {
                    return ProseMirror.DecorationSet.empty;
                }
                const decorations = [];
                const decAttrs = {
                    id: id,
                    class: 'placeholderPlug',
                    'data-placeholder': placeholder !== null && placeholder !== void 0 ? placeholder : '',
                };
                doc.descendants((node, pos) => {
                    decorations.push(ProseMirror.Decoration.node(pos, pos + node.nodeSize, decAttrs));
                });
                return ProseMirror.DecorationSet.create(doc, decorations);
            },
        },
    });
}
