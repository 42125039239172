import { ProseMirror } from '@progress/kendo-react-editor';

export function placeholderPlug(placeholder: string, id: string) {
  return new ProseMirror.Plugin({
    key: new ProseMirror.PluginKey('placeholder'),
    props: {
      decorations: state => {
        const { doc } = state;
        const empty =
          doc.textContent === '' &&
          doc.childCount <= 1 &&
          doc.content.size <= 2;

        if (!empty) {
          return ProseMirror.DecorationSet.empty;
        }

        const decorations: Array<any> = [];
        const decAttrs = {
          id: id,
          class: 'placeholderPlug',
          'data-placeholder': placeholder ?? '',
        };

        doc.descendants((node, pos) => {
          decorations.push(
            ProseMirror.Decoration.node(pos, pos + node.nodeSize, decAttrs),
          );
        });

        return ProseMirror.DecorationSet.create(doc, decorations);
      },
    },
  });
}
