import React, { useEffect, useRef, useState } from 'react';
import { SanitizedDiv, useComposedClassName } from '@rapid/sdk';
import { Editor as KendoEditor, } from '@progress/kendo-react-editor';
import { placeholderPlug } from './placeholder';
import { ProseMirror } from '@progress/kendo-react-editor';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../index';
export function MultilineTextField({ id, value, label, onChange, disabled, placeholder, height, }) {
    var _a;
    const onMultilineChange = (e) => {
        onChange(e.html);
    };
    const [hasFocus, setHasFocus] = useState(false);
    const toggleFocus = (focus) => event => {
        setHasFocus(!!focus);
    };
    const onKendoEditorMount = event => {
        var _a;
        const state = event.viewProps.state;
        const plugins = [...state.plugins, placeholderPlug(placeholder, id)];
        const doc = event.dom.ownerDocument;
        (_a = doc.querySelector('style')) === null || _a === void 0 ? void 0 : _a.appendChild(doc.createTextNode(''));
        return new ProseMirror.EditorView({ mount: event.dom }, {
            ...event.viewProps,
            state: ProseMirror.EditorState.create({ doc: state.doc, plugins }),
        });
    };
    const multiLineClass = useComposedClassName(function* () {
        yield `MultiLineTextField Label flex column`;
        if (hasFocus)
            yield 'focus';
        if (disabled)
            yield 'disabled';
        if (height)
            yield height.toString();
    }, [hasFocus, disabled, height]);
    const labelRef = useRef(null);
    useEffect(function setHeightCssVariableOnMount() {
        if (!!height) {
            console.log('height change');
            labelRef.current.style.setProperty('--multiline-height', `${height}rem`);
        }
    }, [height]);
    return (React.createElement("label", { ref: labelRef, className: multiLineClass, id: id },
        React.createElement("span", { className: "Name" }, label),
        React.createElement("div", { className: `TextField Multiline ant-input ant-input-sm ${hasFocus ? 'focus' : ''}` }, disabled ? (React.createElement(SanitizedDiv, { html: (_a = value !== null && value !== void 0 ? value : placeholder) !== null && _a !== void 0 ? _a : '' })) : (React.createElement(KendoEditor, { defaultEditMode: "div", value: value !== null && value !== void 0 ? value : '', onChange: onMultilineChange, onMount: onKendoEditorMount, onFocus: toggleFocus(true), onBlur: toggleFocus(false) })))));
}
MultilineTextField[titleTag] = 'Multiple lines of text';
MultilineTextField[descriptionTag] =
    'WYSIWYG Rich text editor allowing multiple lines of formatted text';
MultilineTextField[iconTag] = 'fa-align-left';
MultilineTextField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Number',
        id: '$.attributes.height',
        label: 'Height',
        defaultValue: 3,
    },
    {
        $type: 'Input.MultiLineText',
        id: 'defaultValue',
        label: 'Default Value',
        attributes: {
            height: 3,
        },
    },
    {
        $type: 'Input.Text',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
