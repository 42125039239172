import React from 'react';

interface IProgressBarProps {
  percent?: number | null;
}

export function ProgressBar({ percent }: IProgressBarProps) {
  return (
    <progress
      className="Progress"
      value={percent === null ? undefined : percent}
      max="100"
    />
  );
}
