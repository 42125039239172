import { encodeQueryString } from './query-string';
export class RequestError extends Error {
    constructor(response, message = `Request failed with status code ${response.status}`) {
        super(message);
        this.statusCode = response.status;
        this.statusText = response.statusText;
        this.isJson = /json/.test(response.headers.get('content-type'));
        if (this.isJson) {
            response.json().then(data => {
                this.response = data;
            });
        }
        else {
            response.text().then(data => {
                this.response = data;
            });
        }
    }
}
export function isRequestError(error) {
    return error instanceof RequestError;
}
export function isBlob(input) {
    if (!input) {
        return false;
    }
    return input instanceof Blob || Object.prototype.toString.call(input) === '[object blob]';
}
export function isFile(input) {
    if (!input) {
        return false;
    }
    return input instanceof File || Object.prototype.toString.call(input) === '[object File]';
}
/**
 * Creates a recursive `window.fetch` REST client that treats each property access as a
 * sub-resource that also has a REST client with the appropriate URL path appended.
 *
 * @typeparam T T is any TypeScript interface that describes a RESTful {@link APIEndpoint}
 *
 * @example
 * ```ts
 * interface TwitterEndpoint extends APIEndpoint {
 *  // note: TweetsEndpoint and StatusesEndpoint are omitted for brevity
 *  tweets: APIEndpoint<TweetsEndpoint>;
 *  statuses: APIEndpoint<StatusesEndpoint>;
 *
 *  // untyped API endpoints are also supported
 *  retweet: APIEndpoint;
 * }
 *
 *  const client = proxyRestClient<TwitterEndpoint>();
 *
 *  // get tweets
 *  await client.tweets.get();
 *
 *  // lookup status updates
 *  await client.statuses.lookup.get();
 *
 *  // retweet by ID
 *  const tweetId = (...);
 *  await client.retweet[tweetId].post();
 * ```
 *
 * @category API
 */
export function proxyRestClient(resourceUrl, fetch = window.fetch) {
    return new Proxy({
        resourceUrl,
    }, {
        get(target, prop) {
            var _a;
            const match = prop.match(/^(get|head|post|put|delete|connect|options|trace|patch)(String|Json|Blob)?/i);
            if (match === null || match === void 0 ? void 0 : match.length) {
                return async function apiRequest(query, body, contentType = "application/json") {
                    const queryStr = query && encodeQueryString(query);
                    const url = new URL(resourceUrl);
                    if (queryStr) {
                        url.search = '?' + queryStr;
                    }
                    if (isFile(body) || isBlob(body)) {
                        contentType = body.type || 'application/octet-stream';
                    }
                    else {
                        body = typeof body === 'string' ? body : JSON.stringify(body);
                    }
                    const resp = await fetch(url.toString(), {
                        method: match[1].toUpperCase(),
                        body,
                        headers: {
                            "content-type": contentType,
                        }
                    });
                    if (!resp.ok) {
                        throw new RequestError(resp);
                    }
                    switch (match[2]) {
                        case 'Blob':
                            return await resp.blob();
                        case 'Json':
                            return await resp.json();
                        case 'String':
                            return await resp.text();
                        default:
                            return resp;
                    }
                };
            }
            if (typeof target[prop] === 'function') {
                return target[prop];
            }
            return proxyRestClient(`${((_a = target.resourceUrl) !== null && _a !== void 0 ? _a : '').replace(/\/+$/g, '')}/${prop.replace(/\/+$/g, '')}`, fetch);
        },
    });
}
