import React from 'react';
import { useSiteForms } from '@rapid/adaptive-forms';
import { FormsListItem } from './forms-list-item';
import { IEnvTenantSiteParams, useViewContextListItemArray } from '@rapid/sdk';
import { useParams } from 'react-router';

export function FormsList() {
  const [forms] = useSiteForms();
  const params = useParams<IEnvTenantSiteParams>();
  const [items] = useViewContextListItemArray();

  return (
    <div className="FormsList">
      <div className="Details">
        <div className="Title">Forms</div>
        <div className="TenantSite">
          <span className="Tenant">{params.tenant}</span> /{' '}
          <span className="Site">{params.site}</span>
        </div>
      </div>
      <div className="Grid">
        {forms.items.map(f => (
          <FormsListItem legacyForm={f} key={f.slug} />
        ))}
        {items.map(f => (
          <FormsListItem form={f} key={f.slug} />
        ))}
      </div>
    </div>
  );
}
